import CryptoJS from 'crypto-js';
import Base64  from 'base-64';

const key = CryptoJS.enc.Utf8.parse('tujsmzjqzjjiqj1f'); // 十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('g216dgqwe15dghyt'); //十六位十六进制数作为密钥偏移量

const encryptData = (word) => {
    let encrypted = CryptoJS.AES.encrypt(word.toString(CryptoJS.enc.Utf8), key, {
        iv:iv,
        mode: CryptoJS.mode.CBC,
    });
    return encrypted.toString()
};

const decodeData = (word) => {
    let decrypt = CryptoJS.AES.decrypt(word, key, {
        iv:iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
};


window.config = {
    token : '',
    encryptData: encryptData,
    decodeData: decodeData,
}

let token = window.localStorage.getItem('token')
if(token){
    window.config.token = token
}