import React, { lazy } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './services/global';

const Home = lazy(() => import('view/home'))
const Login = lazy(() => import('view/login'))

function App() {
  return (
    <div className="App">
      <Router>
        <React.Suspense>
          {/* 路由读取basename层 */}
          <Routes>
            {/* 小程序 */}
            <Route path={'/login'} key={'login'} element={<Login/>} />
            <Route path={'/*'} key={'home'} element={<Home/>} />
          </Routes>
        </React.Suspense>
      </Router>
    </div>
  );
}

export default App;
